import React, { useState } from 'react';

const ExtrasModal = ({ id, image, name, price, discount_price, extras, onClose, onAddToCart }) => {
    const [localQuantity, setLocalQuantity] = useState(1);
    const [localPrice, setLocalPrice] = useState(discount_price || price);
    const [selectedExtras, setSelectedExtras] = useState({});

    const handleCheckboxChange = (extraId, isChecked) => {
        setSelectedExtras(prevState => ({
            ...prevState,
            [extraId]: isChecked,
        }));
    };

    const handleDecrease = () => {
        const newQuantity = Math.max(localQuantity - 1, 1);
        setLocalQuantity(newQuantity);
        updateLocalPrice(newQuantity);
    };

    const handleIncrease = () => {
        const newQuantity = localQuantity + 1;
        setLocalQuantity(newQuantity);
        updateLocalPrice(newQuantity);
    };

    const updateLocalPrice = (quantity) => {
        let basePrice = discount_price || price;
        setLocalPrice(basePrice * quantity);
    };

    const handleAddToCart = () => {
        const selectedExtrasArray = extras.filter(extra => selectedExtras[extra.id]);

        const groups = {};

        extras.forEach(extra => {
            if (!groups[extra.extra_group_id]) {
                groups[extra.extra_group_id] = [];
            }

            groups[extra.extra_group_id].push(extra);
        });

        for (const groupID in groups) {
            const group = groups[groupID];

            const requiredCount = group.filter(extra => extra.is_required).length;
            const selectedCount = selectedExtrasArray.filter(extra => parseInt(extra.extra_group_id, 10) === parseInt(groupID, 10)).length;

            if (requiredCount > 0 && selectedCount === 0) {
                alert('Необходимо выбрать добавку из группы');
                return;
            }

            if (group.some(extra => extra.is_singled) && selectedCount > 1) {
                alert('Выберите только одну добавку из группы');
                return;
            }
        }

        const transformedExtras = selectedExtrasArray.map(extra => ({
            ...extra,
            count: 1,
            pricewithCount: extra.price,
        }));

        const uniqueId = `${id}-${new Date().getTime()}`;

        const newItem = {
            uniqueId,
            id: null,
            imageUrl: image,
            name,
            price,
            quantity: localQuantity,
            food_id: id,
            extras: transformedExtras
        };

        onAddToCart(newItem);

        onClose();
    };

    return (
        <div className="modal fade show custom-modal" style={{ display: 'block', zIndex: 1050 }}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-sm-6 p-3">
                                <img
                                    src={image}
                                    className="img-fluid"
                                    alt="Food"
                                />
                            </div>
                            <div className="col-12 col-sm-6 p-3">
                                <h5 className="modal-title text-h5 text-bold">{name}</h5>
                                <span className="text-subtitle2 text-bold">Добавки</span>
                                <div className="list-group mt-3">
                                    {extras.map((extra, index) => (
                                        <div key={extra.id}>
                                            {index > 0 && extras[index - 1].extra_group_id !== extra.extra_group_id && <hr />} {/* Добавление разделителя */}

                                            <label
                                                className="q-item q-item-type row no-wrap cursor-pointer d-flex"
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input col-1"
                                                    checked={selectedExtras[extra.id] || false}
                                                    onChange={(e) => handleCheckboxChange(extra.id, e.target.checked)}
                                                />
                                                <span className="text-bold col">{extra.name}</span>
                                                <span className="q-item__label col-2">
                                                    {extra.price !== null ? `${extra.price} ₽` : '0 ₽'}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <div className="d-flex justify-content-start align-items-center me-5">
                                            <button
                                                className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-btn--actionable q-focusable q-hoverable"
                                                onClick={handleDecrease}
                                                tabIndex="0"
                                                type="button"
                                            >
                                                <span className="q-focus-helper"></span>
                                                <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </span>
                                            </button>
                                            <span className='px-3'>&nbsp; {localQuantity} &nbsp;</span>
                                            <button
                                                className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle q-btn--actionable q-focusable q-hoverable"
                                                onClick={handleIncrease}
                                                tabIndex="0"
                                                type="button"
                                            >
                                                <span className="q-focus-helper"></span>
                                                <span className="q-btn__content text-center col align-items-center q-anchor--skip justify-content-center row">
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <button
                                                onClick={handleAddToCart}
                                                className="q-btn q-btn-item non-selectable no-outline q-btn--standard q-btn--rectangle bg-deep-orange text-white q-btn--actionable q-focusable q-hoverable w-100"
                                                tabIndex="0" type="button">
                                                <span className="q-focus-helper" tabIndex="-1"></span>
                                                <span className="q-btn__content text-center col items-center q-anchor--skip justify-content-center row">Добавить
                                                    &nbsp; {localPrice} ₽</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExtrasModal;
